import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardText,
  CardImg,
  Button,
} from "reactstrap";
import TutorNavbar from "../common/TutorNavbar";
import axios from "axios";
import { EditOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { galaxyKids } from "../../assets/styles/globle";
import EditLanguage from "./editProfile/EditLanguage";
import EditContactDetails from "./editProfile/EditContactDetails";
import Footer from "../FooterPart";
import ReactPlayer from "react-player";
import EditEducation from "./editProfile/EditEducation";
import EditWorkExperience from "./editProfile/EditWorkExperience";
import { Upload, message, Form, Input } from "antd";
import EditBasicInfo from "./editProfile/EditBasicInfo";
import AddContactDetails from "../ProfileInformation/AddContactDetails";
function Profile() {
  const [teacher, setTeacher] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [videoFile, setVideoFile] = useState(null);

  const [isModalVisibleBasic, setIsModalVisibleBasic] = useState(false);
  const [isModalVisiblLang, setIsModalVisibleLang] = useState(false);
  const [isModalVisibleContact, setIsModalVisibleContact] = useState(false);
  const [isModalVisibleEdu, setIsModalVisibleEdu] = useState(false);
  const [isModalVisibleWorkExp, setIsModalVisibleWorkExp] = useState(false);
  const [certificateFile, setCertificateFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [picture, setPicture] = useState(null);
  const [youtubeLink, setYoutubeLink] = useState("");

  const [videoUrl, setVideoUrl] = useState(null);
  const [isEdityoutubeLink, setIsYoutubeLink] = useState(false);

  const [form] = Form.useForm();
  
  const fetchTeacher = async () => {
    const teacherId = localStorage.getItem("teacherId"); // Assuming id is the user's ID
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teacher/${teacherId}`
        //   { headers }
      );
      setTeacher(response?.data?.data);
      setLoading(false);
      //setYoutubeLink()
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchTeacher = async () => {
      const teacherId = localStorage.getItem("teacherId"); // Assuming id is the user's ID
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/teacher/${teacherId}`
          //   { headers }
        );
        setTeacher(response?.data?.data);
        if(typeof response?.data?.data?.intro_video == undefined){
          setVideoFile(null);
          setVideoUrl(null);
        }
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };
    fetchTeacher();
  }, [
    imageFile,
    videoFile,
    isModalVisibleBasic,
    isModalVisiblLang,
    isModalVisibleContact,
    isModalVisibleEdu,
    isModalVisibleWorkExp,
  ]);

  const handleRemove = async () => {
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/teacher/intro-video/remove-video/${teacherId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      message.success("Video removed successfully.");
      setVideoFile(null);
      setVideoUrl(null);
    } catch (error) {
      message.error("Failed to remove video.");
      console.error("Failed to remove video", error);
    }
  };

  const handleUpload = async ({ file }) => {
    if(!file.status) {
      const videoUrl = URL.createObjectURL(file);
      const videoElement = document.createElement("video");

      videoElement.src = videoUrl;

      videoElement.onloadedmetadata = () => {
        URL.revokeObjectURL(videoUrl);
        const duration = videoElement.duration;
        if (duration > 60) { // Duration limit of 1 minute (60 seconds)
          message.error("Video duration exceeds the 1-minute limit!");
          setVideoFile(null);
          return;
        } else {
          const fileNameShortened = file.name.substring(0, 10); // Get the first 10 characters of the file name
          const formData = new FormData();
          formData.append("video", file);
          const token = localStorage.getItem("accessToken");
          const teacherId = localStorage.getItem("teacherId");
          axios
            .post(
              `${process.env.REACT_APP_API_URL}/teacher/intro-video/${teacherId}`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((response) => {
              message.success(`${fileNameShortened} uploaded successfully.`);
              setVideoFile(file);
              setVideoUrl(URL.createObjectURL(file)); // Update video URL to the newly uploaded video
              fetchTeacher(); // Fetch teacher data after successful video upload
            })
            .catch((error) => {
              message.error(`${fileNameShortened} upload failed.`);
            });
        }
      };
    }
  };

  const downloadCertificate = async () => {
    const token = localStorage.getItem("accessToken");
    const teacherId = localStorage.getItem("teacherId");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/teacher/download/certificate/${teacherId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob", // Set the response type to blob
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "certificate.pdf";
      document.body.appendChild(anchor);
      anchor.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(anchor);
    } catch (error) {
      message.error("Failed to download certificate. Please try again.");
    }
  };

  const onUpdateLinkFinish = async () => {
    if(youtubeLink){
      const formData = new FormData();
      formData.append("youtube_video_link", youtubeLink);
      const token = localStorage.getItem("accessToken");
      const teacherId = localStorage.getItem("teacherId");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/teacher/intro-video/${teacherId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          message.success("Youtube Link Update successfully");
        } else {
          message.error(response.data.message || "Something went wrong");
        }
      } catch (error) {
        console.error("Error:", error);
        message.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <EditBasicInfo
        imageFile={imageFile}
        setImageFile={setImageFile}
        isModalVisibleBasic={isModalVisibleBasic}
        setPicture={setPicture}
        setIsModalVisibleBasic={setIsModalVisibleBasic}
      />
      <EditLanguage
        isModalVisiblLang={isModalVisiblLang}
        setIsModalVisibleLang={setIsModalVisibleLang}
      />
      {/* <EditContactDetails
        isModalVisibleContact={isModalVisibleContact}
        setIsModalVisibleContact={setIsModalVisibleContact}
      /> */}
      <AddContactDetails
        addContactDetailsModalOpen={isModalVisibleContact}
        setAddContactDetailsModalOpen={setIsModalVisibleContact}
        teacerData={teacher}
        isUpdate={true}
      />
      <EditEducation
        isModalVisibleEdu={isModalVisibleEdu}
        setIsModalVisibleEdu={setIsModalVisibleEdu}
      />
      <EditWorkExperience
        isModalVisibleWork={isModalVisibleWorkExp}
        setIsModalVisibleWork={setIsModalVisibleWorkExp}
      />
      <section style={{ backgroundColor: galaxyKids.backgroundColor, paddingTop: '100px' }}>
        <TutorNavbar loaderProp={loading} />
        <Container className="py-5">
          <Row>
            <Col lg="5">
              <Card className="mb-4">
                <CardBody className="">
                  <div className="mb-3 text-center">
                    <CardImg
                      src={
                        `${process.env.REACT_APP_MEDIA_URL}/user/image/` +
                          teacher?.picture || URL.createObjectURL(imageFile)
                      }
                      id="avatar"
                      className="rounded-circle"
                      style={{ width: "300px", height: "300px" }}
                      fluid
                    />
                  </div>
                  <div className="mb-3">
                    <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }} >First Name:</span> {teacher?.first_name}</p>
                    <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }} >Last Name:</span> {teacher?.last_name}</p>
                    <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }} >Location: </span>
                    {[ teacher?.address?.city, teacher?.address?.state, teacher?.address?.country, teacher?.address?.postcode].filter(Boolean).join(', ')}</p>
                    {/* <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }} >Location:</span> {teacher?.location.coordinates}</p> */}
                    <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }} >Gender:</span> {teacher?.gender}</p>
                    <p className="text-muted mb-0"><span style={{ fontWeight: 'bold' }} >Date of Birth:</span> {teacher?.date_of_birth}</p>
                  </div>
                  <div className="d-flex justify-content-center mb-2">
                    <Button
                      style={{
                        backgroundColor: galaxyKids.textOrange,
                        border: "none",
                      }}
                      onClick={() => setIsModalVisibleBasic(true)}
                    >
                      <EditOutlined />
                    </Button>
                  </div>
                </CardBody>
              </Card>

              <Card className="mb-4 mb-lg-0">
                <CardBody>
                  <CardText style={{ 
                      color: galaxyKids.textOrange, 
                      fontWeight: 'bold', 
                      fontSize: '1.2rem' 
                    }}>
                    Language Info
                  </CardText>
                  <table className="table">
                    <thead>
                      <tr className="text-center" >
                        <th scope="col">Dialect</th>
                        <th scope="col">Language</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teacher?.language_skills?.map((language, index) => (
                        <tr className="text-center" key={index}>
                          <td>{language?.dialect}</td>
                          <td>{language?.language}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </CardBody>
                <CardBody className="p-0 ">
                  <ul className="list-group list-group-flush rounded-3">
                    <li
                      className="list-group-item p-3"
                      onClick={() => setIsModalVisibleLang(true)}
                      style={{
                        cursor: "pointer",
                        color: galaxyKids.textOrange,
                        textAlign: 'center'
                      }}
                    >
                      EDIT
                    <EditOutlined />
                    </li>
                  </ul>
                </CardBody>
              </Card>

              <Card className="my-4 mb-lg-0">
                <CardBody className="">
                  <CardText style={{ 
                      color: galaxyKids.textOrange, 
                      fontWeight: 'bold', 
                      fontSize: '1.2rem' 
                    }}>
                    Introduction
                  </CardText>
                  <label style={{ color: galaxyKids.textColor }}>Uploaded Video</label>
                  <hr />
                  {videoUrl ? (
                    <ReactPlayer
                      url={
                        videoUrl
                      }
                      playing={false}
                      controls={true}
                      width="100%"
                      height="70%"
                    />
                  ) : (
                    teacher?.intro_video && (<ReactPlayer
                      url={
                        `${process.env.REACT_APP_MEDIA_URL}/user/video/` +
                        teacher?.intro_video
                      }
                      playing={false}
                      controls={true}
                      width="100%"
                      height="70%"
                    />)
                  )}
                </CardBody>
                <CardBody className="">
                  <Upload
                    action=""
                    beforeUpload={() => false} // Disable automatic upload
                    onChange={handleUpload}
                    onRemove={handleRemove}
                    fileList={videoFile ? [videoFile] : []}
                    maxCount={1}
                    accept="video/*"
                  >
                    <Button
                      style={{
                        backgroundColor: galaxyKids.textOrange,
                        border: "none",
                      }}
                      icon={<EditOutlined />}
                    >
                      Upload Introduction Video
                    </Button>
                  </Upload>
                </CardBody>
                <CardBody>
                { (youtubeLink && isEdityoutubeLink) ? (<CardBody className="p-2">
                  <label style={{ color: galaxyKids.textColor }}>Youtube Link Video</label>
                    <hr />
                    <ReactPlayer
                      url={youtubeLink}
                      playing={false}
                      controls={true}
                      width="100%"
                      height="70%"
                    />
                  </CardBody>) : (<CardBody className="p-2">
                  <label style={{ color: galaxyKids.textColor }}>Youtube Link Video</label>
                    <hr />
                    <ReactPlayer
                      url={teacher?.youtube_video_link}
                      playing={false}
                      controls={true}
                      width="100%"
                      height="70%"
                    />
                  </CardBody>)}
                
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onUpdateLinkFinish}
                    
                  >
                    { isEdityoutubeLink && (<Form.Item
                      name="youtubeLink"
                      label="YouTube Link"
                      rules={[
                        {
                          required: isEdityoutubeLink,
                          type: 'url',
                          message: 'Please enter a valid URL!',
                        },
                      ]}
                    >
                      <Input
                        value={youtubeLink}
                        onChange={(e) => setYoutubeLink(e.target.value)}
                        placeholder="Enter YouTube link"
                      />
                    </Form.Item>)}
                    <Button
                      className="border-0"
                      style={{ backgroundColor: galaxyKids.textOrange, color: "#fff" }}
                      onClick={() => !isEdityoutubeLink ? setIsYoutubeLink(true) : onUpdateLinkFinish() }
                      loading={loading}
                    >
                      {isEdityoutubeLink ? 'Update' : 'Edit Youtube Link'}
                    </Button>
                    { isEdityoutubeLink && <Button
                      className="border-0 m-1"
                      style={{ backgroundColor: galaxyKids.textColor, color: "#fff" }}
                      onClick={() => setIsYoutubeLink(false)}
                      loading={loading}
                    >
                      Cancel
                    </Button>}
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col lg="7">
              <Card className="mb-4">
                <CardBody>
                  <Row>
                    <Col sm="3">
                      <CardText style={{ color: galaxyKids.textOrange, fontWeight: 'bold', fontSize: '1.2rem' }}>
                        Contact Details
                      </CardText>
                    </Col>
                  </Row>
                  <hr />
                  {teacher?.contact_details?.whatsapp && (
                    <>
                      <Row>
                        <Col sm="3">
                          <CardText style={{ fontWeight: 'bold' }}>WhatsApp</CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-muted">{teacher?.contact_details?.whatsapp}</CardText>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  )}
                  {teacher?.contact_details?.instagram && (
                    <>
                      <Row>
                        <Col sm="3">
                          <CardText style={{ fontWeight: 'bold' }}>Instagram</CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-muted">{teacher?.contact_details?.instagram}</CardText>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  )}
                  {teacher?.contact_details?.line && (
                    <>
                      <Row>
                        <Col sm="3">
                          <CardText style={{ fontWeight: 'bold' }}>Line</CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-muted">{teacher?.contact_details?.line}</CardText>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  )}
                  {teacher?.contact_details?.wechat && (
                    <>
                      <Row>
                        <Col sm="3">
                          <CardText style={{ fontWeight: 'bold' }}>Wechat</CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-muted">{teacher?.contact_details?.wechat}</CardText>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  )}
                  {teacher?.contact_details?.website && (
                    <>
                      <Row>
                        <Col sm="3">
                          <CardText style={{ fontWeight: 'bold' }} >Website</CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-muted">{teacher?.contact_details?.website}</CardText>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  )}
                  {teacher?.contact_details?.mobile_number !== 'null' && (
                    <>
                      <Row>
                        <Col sm="3">
                          <CardText style={{ fontWeight: 'bold' }}>Mobile Number</CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-muted">{teacher?.contact_details?.mobile_number}</CardText>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  )}
                  {teacher?.email && (
                    <>
                      <Row>
                        <Col sm="3">
                          <CardText style={{ fontWeight: 'bold' }}>E-mail Address</CardText>
                        </Col>
                        <Col sm="9">
                          <CardText className="text-muted">{teacher?.email}</CardText>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  )}
                  <Row>
                    <Col onClick={() => setIsModalVisibleContact(true)}>
                      <CardText
                        style={{
                          color: galaxyKids.textOrange,
                          cursor: "pointer",
                          fontWeight: 'bold',
                          textAlign: 'center'
                        }}
                      >
                        EDIT
                        <EditOutlined style={{ marginLeft: '5px' }} />
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Row>
                <Col md="6">
                  <Card className="mb-4 mb-md-0">
                    <CardBody>
                      <CardText
                        style={{ 
                          color: galaxyKids.textOrange, 
                          fontWeight: 'bold', 
                          fontSize: '1.2rem' 
                        }}
                      >
                        Education
                      </CardText>
                      {teacher?.education?.map((d, index) => (
                        <div key={index} className="mb-3">
                          <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Degree:</span>
                            <CardText>{d?.title}</CardText>
                          </div>
                          <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Institution:</span>
                            <CardText style={{ fontSize: ".77rem" }}>
                              {d?.tags?.join(", ")}
                            </CardText>
                          </div>
                          <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Description:</span>
                            <CardText style={{ fontSize: ".77rem" }}>
                              {d?.description}
                            </CardText>
                          </div>
                          <hr />
                        </div>
                      ))}
                      <CardText
                        style={{
                          color: galaxyKids.textOrange,
                          cursor: "pointer",
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                        onClick={() => setIsModalVisibleEdu(true)}
                      >
                        EDIT
                        <EditOutlined style={{ marginLeft: '5px' }} />
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                <Col md="6">
                  <Card className="mb-4 mb-md-0">
                    <CardBody>
                      <CardText
                        style={{ 
                          color: galaxyKids.textOrange, 
                          fontWeight: 'bold', 
                          fontSize: '1.2rem' 
                        }}
                      >
                        Work Experience
                      </CardText>
                      {teacher?.work_experience?.map((d, index) => (
                        <div key={index} className="mb-3">
                          <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Position:</span>
                            <CardText>{d?.position}</CardText>
                          </div>
                          <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Company:</span>
                            <CardText style={{ fontSize: ".77rem" }}>
                              {d?.company_name}
                            </CardText>
                          </div>
                          <div className="mb-2">
                            <span style={{ fontWeight: 'bold' }}>Details:</span>
                            <CardText style={{ fontSize: ".77rem" }}>
                              {d?.details}
                            </CardText>
                          </div>
                          <hr />
                        </div>
                      ))}
                      <CardText
                        style={{
                          color: galaxyKids.textOrange,
                          cursor: "pointer",
                          textAlign: 'center',
                          fontWeight: 'bold',
                        }}
                        onClick={() => setIsModalVisibleWorkExp(true)}
                      >
                        EDIT
                        <EditOutlined />
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>

                {teacher?.certificate && (
                  <Col md="6">
                    <Card className="my-4 mb-md-0">
                      <CardBody>
                        <CardText
                          className="mb-2"
                          style={{ color: galaxyKids.textOrange, fontWeight: 'bold', fontSize: '1.2rem' }}
                        >
                          Certificate Download
                        </CardText>
                        <CardText style={{ fontSize: ".77rem" }}>
                          <Button
                            style={{
                              backgroundColor: galaxyKids.textOrange,
                              border: "none",
                              color: '#fff'
                            }}
                            icon={<ArrowDownOutlined />}
                            onClick={downloadCertificate}
                          >
                            Download Certificate
                          </Button>
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
        <Footer />
      </section>
    </>
  );
}
export default Profile;
