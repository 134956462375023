import React, { useEffect, useState } from "react";
import { Form, Input, Button, Divider, message } from "antd";
import Header from "./Header";
import { galaxyKids } from "../assets/styles/globle";
import Footer from "./FooterPart";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import GoogleLoginButton from './GoogleLoginButton';
import { toast } from "react-toastify";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-top: 62.84px;

  @media (min-width: 768px) {
    padding-top: 76px;
  }
`;

const LoginPage = () => {

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [accessToken, setAccessToken] = useState(null);


  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      navigate("/");
    }
    setAccessToken(accessToken);
  }, [navigate]);

  const handleSubmit = async (values) => {
    const { email, password } = values;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher/login`,
        { email, password }
      );
      localStorage.setItem("accessToken", response?.data?.data?.accessToken);
      localStorage.setItem("teacherId", response?.data?.data?.teacher?._id);
      message.success(response?.data?.message);
      if (response?.data?.data?.teacher?.isVerified) {
        navigate("/");
      } else {
        navigate("/profileinformation");
      }
    } catch (error) {
      //message.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSuccess = (response) => {
    if (response?.data?.statusCode === 200) {
      localStorage.setItem("accessToken", response?.data?.data?.accessToken);
      localStorage.setItem("teacherId", response?.data?.data?.teacher?._id);
      if (response?.data?.data?.teacher?.isVerified) {
        navigate("/");
      } else {
        navigate("/profileinformation");
      }
    }
  };

  const handleError = (error) => {
    //console.error('Login failed:', error);
    message.error(error?.response?.data?.message);
  };

  return (
    <div style={{ backgroundColor: galaxyKids.backgroundColor }}>
      <Header />
      <Wrapper>
        <div className="container-fluid">
          <div
            className="row no-gutter"
            style={{ backgroundColor: galaxyKids.backgroundColor }}
          >
            <div className="col-md-6 d-none d-md-flex bg-image">
              <img
                src={require("../assets/images/register.png")}
                alt="Your Image"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="col-md-6 py-4">
              <div className="login d-flex align-items-center justify-content-center py-4">
                <div className="container py-4">
                  <div className="row py-4">
                    <div className="col-lg-10 mx-auto py-4">
                      <h2
                        style={{
                          color: galaxyKids.textOrange,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "32px",
                        }}
                        className="py-4"
                      >
                        Welcome Back to Tutor SignIn
                      </h2>
                      <Form form={form} onFinish={handleSubmit} initialValues={{ email: "", password: "" }} >
                        <Form.Item name="email" rules={[ { required: true, message: "Please enter your email" }, ]} >
                          <Input
                            className="shadow p-3 bg-white rounded"
                            type="email"
                            placeholder="Enter Your Email"
                          />
                        </Form.Item>
                        <Form.Item name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password",
                            },
                          ]} >
                          <Input.Password
                            className="shadow p-3 bg-white rounded"
                            placeholder="Enter Your Password"
                          />
                        </Form.Item>
                        <p
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <Link
                            to={"/forgotpassword"}
                            style={{ textDecoration: "none" }}
                          >
                            Forgot password?
                          </Link>
                        </p>
                        <div className="mb-3">
                          <Divider plain>OR</Divider>
                        </div>
                        <div className="d-flex justify-content-center">
                          <div
                            style={{ height: "auto" }}
                            className=""
                          >
                            <GoogleLoginButton onSuccess={handleSuccess} onError={handleError} />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">
                          <Button
                            className="shadow p-3 rounded"
                            style={{
                              backgroundColor: galaxyKids.textOrange,
                              color: "white",
                              border: "none",
                              width: "50%",
                              height: "50px",
                            }}
                            htmlType="submit"
                          >
                            LOGIN
                          </Button>
                        </div>
                        <p className="mt-3 text-center">
                          Don't have an Account?{" "}
                          <span style={{ color: galaxyKids.textColor }}>
                            <Link
                              to={"/register"}
                              style={{ textDecoration: "none" }}
                            >
                              Register
                            </Link>
                          </span>
                        </p>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
};

export default LoginPage;
