import React, { useRef, useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import Header from "./Header";
import { galaxyKids } from "../assets/styles/globle";
import Footer from "./FooterPart";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Divider, message } from "antd";
import { toast } from "react-toastify";
import Select from 'react-select';
import GoogleLoginButton from './GoogleLoginButton';
import { useSettings } from '../context/CountryContext';
import styled from "styled-components";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";

const Wrapper = styled.div`
  padding-top: 62.84px;

  @media (min-width: 768px) {
    padding-top: 76px;
  }
`;

const center = {
  lat: 37.7749, // Default latitude (e.g., San Francisco)
  lng: -122.4194, // Default longitude
};

const libraries = ["places"];

const Register = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [accessToken, setAccessToken] = useState(null);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedLat, setLat] = useState(null);
  const [selectedLong, setLong] = useState(null);
  const [mapCenter, setMapCenter] = useState(center);

  const autocompleteRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const languageOptions = [];
  const { configuredSettings, loading } = useSettings();
  configuredSettings?.data?.languages.map((data) => {
    languageOptions.push({ value: data, label: data });
  })

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      navigate("/");
    }
    setAccessToken(accessToken);
  }, [navigate]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/countries`);
        setCountries(response.data.data);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const fetchStates = async (countryId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/states/${countryId}`);
      setStates(response.data.data);
      setCities([]); // Clear cities when country changes
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/cities/${stateId}`);
      setCities(response.data.data);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleCountryChange = (option) => {
    setSelectedCountry(option);
    fetchStates(option.value);
    setSelectedState(null);
    setSelectedCity(null);
  };

  const handleStateChange = (option) => {
    setSelectedState(option);
    fetchCities(option.value);
    setSelectedCity(null);
  };

  const handleCityChange = (option) => {
    console.log("Test >> ", option)
    setSelectedCity(option);
  };

  const handleSubmit = async (values) => {

    values.isVerified = false;
    const formattedCoordinates = [
      parseFloat(selectedLong).toFixed(4),
      parseFloat(selectedLat).toFixed(4),
    ];
    values.location = { coordinates: formattedCoordinates, type: "Point" };
    values.address = {
      city: selectedCity,
      state: selectedState,
      country: selectedCountry
    }
    const data = {
      ...values,
      language_skills: [
        {
          language: values.language.label,
          dialect: "",
        },
      ],
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/teacher/register`,
        data
      );
      const accessToken = response?.data?.data?.accessToken;
      const teacherId = response?.data?.data?.teacher?._id;
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("teacherId", teacherId);
      toast.success(response?.data?.message);

      // Make the additional API call
      const requestResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/request`,
        {
          teacher_id: teacherId,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Request response:', requestResponse.data);

      navigate("/dashboard");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSuccess = (response) => {
    if (response?.data?.statusCode === 200) {
      localStorage.setItem("accessToken", response?.data?.data?.accessToken);
      localStorage.setItem("teacherId", response?.data?.data?.teacher?._id);
      if (response?.data?.data?.teacher?.isVerified) {
        navigate("/");
      } else {
        navigate("/profileinformation");
      }
    }
  };

  const handleError = (error) => {
    message.error(error?.response?.data?.message);
  };

  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = async () => {
    if (autocompleteRef.current) {
        const place = autocompleteRef.current.getPlace();
        
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();

        const result = extractAddressComponents(place.address_components);
        form.setFieldsValue({ location: result.country });
        setSelectedCountry(result.country)
        setSelectedState(result.state)
        setSelectedCity(result.city)
        setLat(lat);
        setLong(lng);
        await setMapCenter({ lat, lng });
    }
  };

  function extractAddressComponents(addressComponents) {
    let area = null;
    let streetAddress = null;
    let apartmentNo = null;
    let country = null;
    let state = null;
    let city = null;
    let latitude = null;
    let longitude = null;

    addressComponents.forEach(component => {
      if (component.types.includes("locality")) {
        city = component.long_name; // City (e.g., "London")
      } else if (component.types.includes("administrative_area_level_1")) {
        state = component.long_name; // State/Province (e.g., "California")
      } else if (component.types.includes("country")) {
        country = component.long_name; // Country (e.g., "United States")
      } else if (component.types.includes("sublocality_level_1") || component.types.includes("sublocality")) {
        area = component.long_name; // Sub-locality (e.g., "Marylebone")
      } else if (component.types.includes("route")) {
        streetAddress = component.long_name; // Street name (e.g., "Baker Street")
      } else if (component.types.includes("street_number")) {
        streetAddress = `${component.long_name} ${streetAddress || ""}`; // Street number + name
      } else if (component.types.includes("subpremise")) {
        apartmentNo = component.long_name; // Apartment/Villa No (e.g., "Flat 2")
      }
    });

    return { area, streetAddress, apartmentNo, country, state, city};
  }

  if (loadError) return <div>Error loading wait..</div>;
  if (!isLoaded) return (<div>loading wait..</div>);

  return (
    <div style={{ backgroundColor: galaxyKids.backgroundColor }}>
      <Header />
      <Wrapper>
        <div className="container-fluid">
          <div className="row no-gutter" style={{ backgroundColor: galaxyKids.backgroundColor }}>
            <div className="col-md-6 d-none d-md-flex bg-image">
              <img
                src={require("../assets/images/register.png")}
                alt="Your Image"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </div>
            <div className="col-md-6 py-4">
              <div className="login d-flex align-items-center justify-content-center py-4">
                <div className="container py-4">
                  <div className="row py-4">
                    <div className="col-lg-10 mx-auto py-4">
                      <h2
                        style={{
                          color: galaxyKids.textOrange,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "32px",
                        }}
                        className="py-4"
                      >
                        Start teaching on Quippy Platform
                      </h2>
                      <Form
                        form={form}
                        onFinish={handleSubmit}
                        initialValues={{
                          name: "",
                          first_name: "",
                          last_name: "",
                          email: "",
                          password: "",
                          language: "",
                          location: "",
                        }}
                      >
                        <Form.Item
                          name="first_name"
                          rules={[
                            { required: true, message: "Please enter your first name" },
                          ]}
                        >
                          <Input
                            className="shadow p-3 bg-white rounded placeholder-gray"
                            placeholder="Enter Your First Name"
                          />
                        </Form.Item>
                        <Form.Item
                          name="last_name"
                          rules={[
                            { required: true, message: "Please enter your last name" },
                          ]}
                        >
                          <Input
                            className="shadow p-3 bg-white rounded placeholder-gray"
                            placeholder="Enter Your Last Name"
                          />
                        </Form.Item>
                        <Form.Item
                          name="email"
                          rules={[
                            { required: true, message: "Please enter your email" },
                            {
                              type: "email",
                              message: "Please enter a valid email",
                            },
                          ]}
                        >
                          <Input
                            className="shadow p-3 bg-white rounded"
                            placeholder="Enter Your Email"
                          />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password",
                            },
                          ]}
                        >
                          <Input.Password
                            className="shadow p-3 bg-white rounded"
                            placeholder="Enter Your Password"
                          />
                        </Form.Item>
                        <Form.Item
                          name="confirmPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "The two passwords that you entered do not match"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            className="shadow p-3 bg-white rounded"
                            placeholder="Confirm Password"
                          />
                        </Form.Item>
                        <Form.Item
                          name="language"
                          rules={[
                            {
                              required: true,
                              message: "Please select your language skills",
                            },
                          ]}
                        >
                          <Select
                            className="shadow p-3 bg-white rounded"
                            value={languageOptions.find(option => option.value === form.getFieldValue('language'))}
                            options={languageOptions}
                            placeholder="Please select your Native language"
                            onChange={value => form.setFieldsValue({ language: value })}
                          />
                        </Form.Item>
                        {/* <Form.Item
                          name="country"
                          rules={[
                            {
                              required: true,
                              message: "Please select your country",
                            },
                          ]}
                        >
                          <Select
                            className="shadow p-3 bg-white rounded"
                            value={selectedCountry}
                            options={countries.map(country => ({ value: `${country.id}/${country.iso2}`, label: country.name }))}
                            placeholder="Select your country"
                            onChange={handleCountryChange}
                          />
                        </Form.Item> */}
                        {/* <Form.Item
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: "Please select your state",
                            },
                          ]}
                        >
                          <Select
                            className="shadow p-3 bg-white rounded"
                            value={selectedState}
                            options={states.map(state => ({ value: `${state.id}/${state.state_code}`, label: state.name }))}
                            placeholder="Select your state"
                            onChange={handleStateChange}
                            isDisabled={!selectedCountry}
                          />
                        </Form.Item> */}
                        {/* <Form.Item
                          name="city"
                          rules={[
                            {
                              required: true,
                              message: "Please select your city",
                            },
                          ]}
                        >
                          <Select
                            className="shadow p-3 bg-white rounded"
                            value={selectedCity}
                            options={cities.map(city => ({ value: city, label: city.name }))}
                            placeholder="Select your city"
                            onChange={handleCityChange}
                            isDisabled={!selectedState}
                          />
                        </Form.Item> */}
                        <Form.Item
                          name="location"
                          rules={[
                            {
                              required: true,
                              message: "Please enter location",
                            }
                          ]}
                        >
                          <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                            <input style={{ border: "none", }} type="text" placeholder="Search location" className="shadow p-3 bg-white rounded w-100"/>
                          </Autocomplete>
                        </Form.Item>

                        <div className="mb-3">
                          <Divider plain>OR</Divider>
                        </div>
                        <div className="d-flex justify-content-center">
                          <div
                            style={{ height: "auto" }}
                            className=""
                          >
                            <GoogleLoginButton onSuccess={handleSuccess} onError={handleError} />
                          </div>
                        </div>
                        <p className="text-center">
                          By Registering, you agree to our&nbsp;
                          <span style={{ color: galaxyKids.textColor }}>
                            User Agreement
                          </span>
                          &nbsp; And&nbsp;
                          <span style={{ color: galaxyKids.textColor }}>
                            Privacy Policy
                          </span>
                        </p>
                        <div className="d-flex justify-content-center">
                          <Button
                            htmlType="submit"
                            className="shadow p-3 rounded"
                            style={{
                              backgroundColor: galaxyKids.textOrange,
                              color: "#ddd",
                              border: "none",
                              width: "50%",
                              height: "50px",
                            }}
                          >
                            CREATE ACCOUNT
                          </Button>
                        </div>
                        <p className="mt-3 text-center">
                          Already have an Account?{" "}
                          <span style={{ color: galaxyKids.textColor }}>
                            <Link style={{ textDecoration: "none" }} to={"/login"}>
                              Login
                            </Link>
                          </span>
                        </p>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </div>
  );
};

export default Register;
